
/* eslint-disable-next-line lines-around-comment */
/**
 * This Vue component contains the filters display on Team Jigs page.
 *
 * Filters includes:
 * - 1. Jigs type filter (All Jigs/Shared Jigs/None shared Jigs) --> Team Admin only
 * - 2. Tenant user filter
 *
 * Key features:
 * - 1. On each filter updates, emit relevant event and updated filter value, parent template will process front end filter accordingly.
 * - 2. Clear and reset filters, and emit relevant events too.
 *
 * Filter events get emit:
 * - 1. `on-share-option-change`: returned payload `selectedShareOption`
 * - 2. `on-selected-user-change`: returned payload `selectedUsers`
 */

import UserAvatar from '@/components/image/UserAvatar.vue'
import JigTooltip from '@/components/input/JigTooltip.vue'
import { UtilsConst } from '@/constants'
import JigsFiltersMixin from '@/mixin/JigsFilters'
import { TenantUser } from '@/store/modules/app/types'
import { Namespace, StandardObject } from '@/store/types'
import { DataTableConfig } from '@/utils/data-table-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import UsersPreviewList from './UsersPreviewList.vue'

@Component({
  components: {
    'jig-tooltip': JigTooltip,
    'user-avatar': UserAvatar,
    'users-preview-list': UsersPreviewList,
  },
})
export default class TeamJigsFilterVue extends mixins(JigsFiltersMixin) {
  @Prop({ type: Array, default: () => [] })
  public removedUsers!: TenantUser[]

  @State('currentSignedInUser', { namespace: Namespace.App })
  public currentSignedInUser!: TenantUser | null
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean

  @Watch('removedUsers')
  private onRemovedUsersChanged(value: TenantUser[]) {
    this.removedUsersList = [...value]
    this.selectedUsers = [...this.allJigOwnersList]
    this.$emit('on-selected-user-change', this.selectedUsers)
  }

  private previewLength: number = 4
  private teamJigsOptions: StandardObject[] = DataTableConfig.jigsList.loadConfig.shareOptions

  private selectedShareOption: StandardObject = this.teamJigsOptions[0]

  protected mounted() {
    this.removedUsersList = [...this.removedUsers]
    this.selectedUsers = [...this.allJigOwnersList]
  }

  // Reset all filters when component is unloaded.
  // This can help Jig List be loaded correctly when switching between My Jigs and Team Jigs page
  protected beforeDestroy() {
    this.clearFilters()
  }

  private get isFilterActive(): boolean {
    return this.selectedShareOption.value !== '' || this.selectedUsers.length !== this.allJigOwnersList.length
  }

  private get isAdminRole(): boolean {
    return TenantHelpers.IsTeamAdmin(this.currentSignedInUser) || this.isSuperUser
  }

  private get tenantUsersPreview(): TenantUser[] {
    const startIndex = this.selectedUsers.length < this.previewLength ? this.selectedUsers.length : this.previewLength
    const selectedUserUIDs = this.selectedUsers.map((u: TenantUser) => u.Uid)

    return this.selectedUsers
      .slice(0, startIndex)
      .concat(this.allJigOwnersList.filter((u: TenantUser) => !selectedUserUIDs.includes(u.Uid)).slice(0, this.previewLength - startIndex))
  }

  public get InitialShareOption(): StandardObject {
    return this.isAdminRole ? this.selectedShareOption : this.teamJigsOptions[1]
  }

  private getUserDisplayName(user: TenantUser) {
    if (user.email === '' || user.email === 'N/A') {
      return 'Unknown user'
    }

    return user.name || user.username || user.email
  }

  private getUserToolTipCopy(user: TenantUser) {
    if (user.email === '' || user.email === 'N/A') {
      return `Removed user (UID: ${user.Uid})`
    }

    return user.email
  }

  private toggleSelectedUser(user: TenantUser) {
    const index: number = this.selectedUserIndex(user)

    if (index === UtilsConst.invalidIndex) {
      // Add user if not already selected
      this.selectedUsers.push(user)
    } else {
      // Remove user if already selected
      this.selectedUsers.splice(index, 1)
    }
    this.$emit('on-selected-user-change', this.selectedUsers)
  }

  private onUserSelectChange() {
    this.$emit('on-selected-user-change', this.selectedUsers)
  }

  private onShareOptionChange() {
    this.$emit('on-share-option-change', this.selectedShareOption)
  }

  private clearFilters() {
    this.selectedShareOption = this.teamJigsOptions[0]
    this.resetSelectedUsers()
    this.$emit('on-filters-reset', {
      selectedShareOption: this.selectedShareOption,
      selectedUsers: this.selectedUsers,
    })
  }
}
