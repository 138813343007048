
import { MueConst } from '@/constants'
import FiltersMixin from '@/mixin/Filters'
import JigLock from '@/mixin/JigLock'
import { Tenant } from '@/store/modules/app/types'
import { ImageValidationResult, Namespace, UploadThumbnailRequest } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

/**
 * The UploadThumbnailVue is responsible for uploading a Jig thumbnail.
 * It should contain MUE Lock system the same way as other JigMetadata relevant actions.
 */
@Component({})
export default class UploadThumbnailVue extends mixins(FiltersMixin, JigLock) {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @Action('uploadJigThumbnail', { namespace: Namespace.Jig }) public uploadJigThumbnail: any

  private isShowUploadThumb: boolean = false
  private isUploadingThumbnail: boolean = false
  private jigThumbnailToUpload!: File
  private thumbnailUploadError: any = {
    isActive: false,
    copy: MueConst.nonSystemErrors.unprocessableEntity.copy,
  }
  private jigThumbnailToUploadArrayBuffer: any = ''
  private isThumbFileSelected: boolean = false
  private isThumbnailUploadValid: boolean = false
  private uploadExternalValidationErrors: string[] = []
  private uploadRules: any = ValidationRules.PngImageUpload

  public ShowModal() {
    this.isShowUploadThumb = true
  }

  public CloseModal() {
    this.isShowUploadThumb = false
  }

  private async uploadThumbnailClick(isPostRequest?: any) {
    this.isUploadingThumbnail = true
    const isLockObtained = await this.RequestNewLock()
    if (!isLockObtained) {
      this.isUploadingThumbnail = false
      return
    }

    const isFirstThumbnail: boolean = isPostRequest != null ? isPostRequest : this.hasThumbnailPlaceHolder(this.jigMetadata.ThumbnailURL)

    const req: UploadThumbnailRequest = {
      Id: this.jigMetadata.Id,
      TenantId: this.myTenant.ID,
      Thumbnail: this.jigThumbnailToUpload,
      IsFirstThumbnail: isFirstThumbnail,
      version: this.jigMetadata.Version,
    }

    if (!isFirstThumbnail) {
      req.DateUpdated = this.jigMetadata.DateUpdated
    }
    const thumbnailUploadResults = await this.uploadJigThumbnail(req)

    if (thumbnailUploadResults.isSuccess) {
      await this.releaseLock(this.lockPayload)
      // Pass resolve so that parent can call resolve once `await this.loadJigAndReset()` is done
      await new Promise<void>((resolve) => {
        this.$emit('request-load-reset', resolve)
      })
    } else if (thumbnailUploadResults.status === MueConst.nonSystemErrors.conflict.status) {
      // If a client sends a POST request when the server expects a PUT request, the server will respond with a 409 error.
      // Fallback to a PUT request and try again.
      // This most likely will happen to old Jigs.
      await this.uploadThumbnailClick(false)
      return
    } else if (thumbnailUploadResults.status === MueConst.nonSystemErrors.unprocessableEntity.status) {
      this.thumbnailUploadError.isActive = true
      return
    }

    this.isUploadingThumbnail = false
    this.CloseModal()
  }

  private async onThumbnailErrorRefreshClick() {
    this.isUploadingThumbnail = false
    this.CloseModal()
    await new Promise<void>((resolve) => {
      this.$emit('request-data-reload', resolve)
    })
    this.thumbnailUploadError.isActive = false
  }

  private async thumbFileInputChange(file: File) {
    // To display a thumbnail preview, we set a v-img src to the FileReader result.
    if (FileReader && file && file.size) {
      const fr = new FileReader()
      fr.onload = () => {
        this.jigThumbnailToUploadArrayBuffer = fr.result
      }
      fr.readAsDataURL(file)
    } else {
      this.jigThumbnailToUploadArrayBuffer = ''
    }
    if (file && file.size) {
      this.jigThumbnailToUpload = file
      this.isThumbFileSelected = true
      this.uploadExternalValidationErrors = []
      this.isThumbnailUploadValid = false
      await ValidationRules.ImageSizeJigThumbnail(file).then((result: ImageValidationResult) => this.onThumbnailValidationComplete(result))
    } else {
      this.isThumbFileSelected = false
    }
  }

  private onThumbnailValidationComplete(result: ImageValidationResult) {
    this.isThumbnailUploadValid = result.valid
    if (!result.valid) {
      this.uploadExternalValidationErrors = [
        `Thumbnail dimensions must be no larger than
        ${result.xMax} x ${result.yMax}px. Supplied dimensions: ${result.x} x ${result.y}px. `,
      ]
    }
  }
}
