
import HintTooltip from '@/components/helper/HintTooltip.vue'
import IconInfoLabel from '@/components/svg/IconInfoLabel.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    'hint-tooltip': HintTooltip,
    'icon-info-label': IconInfoLabel,
  },
})
export default class LabelWithHintVue extends Vue {
  @Prop({ type: String, default: '' })
  public labelFor!: string
  @Prop({ type: String, default: '' })
  public labelCopy!: string
  @Prop({ type: String, default: 'hinttip' })
  public refKey!: string
  @Prop({ type: Boolean, default: false })
  public hasCloseButton!: boolean
  @Prop({ type: String, default: '' })
  public tipCopy!: string
  @Prop({ type: String, default: 'xs', validator: (value: string) => ['', 'xs', 'sm', 'md', 'lg'].includes(value) })
  public size!: string
  @Prop({
    type: String,
    default: 'align-rb',
    validator: (value: string) => ['left', 'right', 'top', 'bottom', 'align-lb', 'align-rb', 'align-lc', 'align-rc'].includes(value),
  })
  public position!: string
  @Prop({
    type: String,
    default: 'lb',
    validator: (value: string) => ['tl', 'tr', 'tc', 'bl', 'br', 'bc', 'lt', 'lb', 'lc', 'rt', 'rb', 'rc'].includes(value),
  })
  public arrowPosition!: string
  @Prop({ type: String, default: 'light' })
  public hinttipClass!: string

  public $refs!: Vue['$refs'] & {
    [key: string]: HintTooltip
  }

  private showTooltip() {
    this.$refs[this.refKey].showHintTooltip()
  }

  private hideTooltip() {
    this.$refs[this.refKey].hideHintTooltip()
  }
}
