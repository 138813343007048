var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._g(_vm._b({staticClass:"user-select-trigger user__info"},'div',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.userList),function(user,index){return _c('div',{key:`preview-${user.Uid}`,class:{
      filter__user: true,
      'user--selected': _vm.selectedUserIndex(user, _vm.flaggedUsers) !== -1,
    },style:({
      zIndex: _vm.userList.length - index,
    })},[_c('jig-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tipConfig){return [_c('user-avatar',_vm._g(_vm._b({attrs:{"userEmail":user.email,"userName":user.name || user.username}},'user-avatar',tipConfig.attrs,false),tipConfig.on))]}}],null,true)},[_c('div',{staticClass:"al-left",domProps:{"innerHTML":_vm._s(_vm.getUser(user.Uid).tooltipDisplay)}})])],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tenantUsers.length > _vm.previewLength),expression:"tenantUsers.length > previewLength"}],class:{
      'user-more': true,
      'user-more--alt': _vm.flaggedUsers.length >= _vm.previewLength,
    }},[_vm._v(" +"+_vm._s(_vm.tenantUsers.length - _vm.previewLength)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }