
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import IconInfoFilled from '@/components/svg/IconInfoFilled.vue'
import { JigConst } from '@/constants'
import { Namespace, StandardObject } from '@/store/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { ClosePromptFunction, ShowPromptFunction } from '../modals/type'

/**
 * The JigWarningModals contains relevant warning prompt modals for Jig visibility change, it has following modals:
 * - 1. linkJigToPrivatePromptYesNo: change Jig from `link` to `private`
 * - 2. linkJigToPasswordPromptYesNo: change Jig from `link` to `link-password`.
 * - 3. privateJigToLinkPromptYesNo: change Jig from `private`/`link-password` to `link`
 * - 4. publicJigToPrivatePromptYesNo: turn off `Public sharing` which is making Jig to `private`
 * - 5. jigPasswordRegeneratePromptYesNo: when change Jig to `link-password` and a password already generated before
 * - 7. publishingJigPromptYesNo: publish Jig (this `public` visibility is JigStaff Superuser only)
 *
 * Other modals included:
 * - 8. saveJigErrorPrompt: Handles errors when saving Jig changes.
 *
 * Notes:
 * - 1. `public` visiblity option is only available for JigStaff/SuperUser.
 * - 2. `Pulbic sharing` switch is on if Jig visibility is `link` / `public`, otherwise it's off.
 * - 2. If Jig original visibility is `link` / `public`, turning off `Public sharing` switch will set visibility to `private
 * - 3. If Jig original visibility is `private` / `link-password`, turning on `Public sharing` switch will set visibility to `link`
 */
@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'icon-info-filled': IconInfoFilled,
  },
})
export default class JigWarningModalsVue extends Vue {
  @Prop({ type: Boolean, default: false })
  public isProcessing!: boolean

  public $refs!: Vue['$refs'] & {
    jigPasswordRegeneratePromptYesNo: PromptYesNo[]
    linkJigToPasswordPromptYesNo: PromptYesNo[]
    linkJigToPrivatePromptYesNo: PromptYesNo[]
    privateJigToLinkPromptYesNo: PromptYesNo[]
    publicJigToPrivatePromptYesNo: PromptYesNo[]
    publishingJigPromptYesNo: PromptYesNo[]
    saveJigErrorPrompt: PromptYesNo[]
  }

  private isProcessingPromptYesNo: boolean = false
  private saveJigError: string = ''

  @Getter('jigVisibility', { namespace: Namespace.Jig })
  public jigVisibility!: string
  @Getter('jigPassword', { namespace: Namespace.Jig })
  public jigPassword!: string

  @Mutation('changeLoadingState', { namespace: Namespace.Utils })
  public changeLoadingState: any

  @Watch('isProcessing')
  private onIsProcessingChanged(value: boolean) {
    this.isProcessingPromptYesNo = value
  }

  /**
   * Returns a collection of predefined prompt configurations for various Jig-related actions.
   * Returned value is used in dynamic component v-for rendering to reduce html code. Prompt details comments can be found on start of this file.
   *
   * Each prompt configuration includes properties for buttons, slots for title and content, and event handlers for user actions.
   *
   * @returns {Object} An object containing all the prompt configurations.
   */
  private get jigPrompts(): StandardObject {
    return {
      linkJigToPrivatePromptYesNo: {
        props: {
          yesClickEvent: this.requestChangeJigToPrivate,
          isProcessing: this.isProcessingPromptYesNo,
          yesBtnMsg: 'Save',
        },
        slots: {
          title: {
            class: '',
            html: 'Disabling shareable links',
          },
          content: {
            class: '',
            html: `You changed the visibility of this Jig from <strong>"Anyone with link can view"</strong> to <strong>"Private (Invite only)"</strong>. <br />All pre-existing links to this Jig will be disabled.<br /><br />Are you sure?`,
          },
        },
        events: {
          'on-answer-no': () => this.requestStatusReset(),
        },
      },
      linkJigToPasswordPromptYesNo: {
        props: {
          yesClickEvent: this.changeJigToLinkPasswordConfirmed,
          isProcessing: this.isProcessingPromptYesNo,
          yesBtnMsg: 'Save',
        },
        slots: {
          title: {
            class: '',
            html: 'Disabling shareable links',
          },
          content: {
            class: '',
            html: `You changed the visibility of this Jig from <strong>"Anyone with link can view"</strong> to <strong>"Password protected"</strong>. <br />People outside your team need password to view.<br /><br />Are you
            sure?`,
          },
        },
        events: {
          'on-answer-no': () => this.requestStatusReset(),
        },
      },
      privateJigToLinkPromptYesNo: {
        props: {
          yesClickEvent: this.changeJigToLinkConfirmed,
          isProcessing: this.isProcessingPromptYesNo,
          yesBtnMsg: 'Yes',
        },
        slots: {
          title: {
            class: '',
            html: 'Enable public sharing?',
          },
          content: {
            class: '',
            html: `You are enabling <strong>Public Sharing</strong> for this Jig.<br />Anyone with a link to this Jig will be able to view it.<br />Consider using secure sharing via the "Manage access" button instead.<br /><br />Are you sure?`,
          },
        },
        events: {
          'on-answer-no': () => this.requestStatusReset(),
        },
      },
      publicJigToPrivatePromptYesNo: {
        props: {
          yesClickEvent: this.requestChangeJigToPrivate,
          isProcessing: this.isProcessingPromptYesNo,
          yesBtnMsg: 'Yes',
        },
        slots: {
          title: {
            class: '',
            html: 'Disable public sharing?',
          },
          content: {
            class: '',
            html: `You are disabling <strong>Public Sharing</strong> for this Jig.<br />Only authorized users will be able to view this Jig.<br />Existing links shared with unauthorized users will stop working.<br />You can give more people access via the "Manage access" button.<br /><br />Are you sure?`,
          },
        },
        events: {
          'on-answer-no': () => this.requestStatusReset(),
        },
      },
      jigPasswordRegeneratePromptYesNo: {
        props: {
          noClickEvent: () => {
            this.regeneratePasswordConfirmed(false)
          },
          yesClickEvent: () => {
            this.regeneratePasswordConfirmed(true)
          },
          isProcessing: this.isProcessingPromptYesNo,
          yesBtnMsg: 'New password',
          noBtnMsg: 'Keep existing',
        },
        slots: {
          title: {
            class: 'modal__title--flex',
            icon: {
              is: 'icon-info-filled',
              class: 'modal__icon icon-info-filled',
            },
            html: `Generate new
            password?`,
          },
          content: {
            class: '',
            html: `The current password is <span class="text--password">${this.jigPassword}</span>. Would like to generate a new one?`,
          },
        },
        events: {},
      },
      saveJigErrorPrompt: {
        props: {
          yesBtnMsg: 'Refresh',
          noBtnMsg: 'Cancel',
          isYesBtnOutlined: true,
        },
        slots: {
          title: {
            class: '',
            html: 'Could not save changes',
          },
          content: {
            class: '',
            html: this.saveJigError,
          },
        },
        events: {
          'on-answer-no': () => this.changeLoadingState(false),
          'on-answer-yes': () => this.requestLoadAndReset(),
        },
      },
      publishingJigPromptYesNo: {
        props: {
          yesBtnMsg: 'Save',
          yesClickEvent: () => {
            this.requestCheckAndSave()
          },
          isProcessing: this.isProcessingPromptYesNo,
        },
        slots: {
          title: {
            class: '',
            html: 'Publish Jig',
          },
          content: {
            class: '',
            html: `<p>You set the visibility of this Jig to "${this.jigVisibility}".</p><p>${
              this.jigVisibility === JigConst.JigVisibilities.Public
                ? 'Are you sure you want to publish this Jig?'
                : 'Are you sure you want to hide this Jig from the public?'
            }</p>`,
          },
        },
        events: {
          'on-answer-no': () => this.requestStatusReset(),
        },
      },
    }
  }

  private async requestCheckAndSave() {
    this.$emit('request-check-and-save')
  }

  private async requestChangeJigToPrivate() {
    this.$emit('request-change-jig-to-private')
  }

  private requestLoadAndReset() {
    this.$emit('request-load-reset')
  }

  private requestStatusReset() {
    this.$emit('request-status-reset')
  }

  private changeJigToLinkPasswordConfirmed() {
    if (!!this.jigPassword) {
      this.$callRefMethod<ShowPromptFunction>('jigPasswordRegeneratePromptYesNo', 'ShowPrompt')
    } else {
      this.$emit('request-password-regenerate', false)
    }
  }

  private changeJigToLinkConfirmed() {
    this.$emit('request-change-jig-to-link')
  }

  private regeneratePasswordConfirmed(regeneratePassword: boolean) {
    this.$emit('request-password-regenerate', regeneratePassword)
  }

  public TriggerSaveJigError(message: string) {
    this.saveJigError = message
    this.$callRefMethod<ShowPromptFunction>('saveJigErrorPrompt', 'ShowPrompt')
  }

  public ShowPrompt(refKey: string) {
    this.$callRefMethod<ShowPromptFunction>(refKey, 'ShowPrompt')
  }

  public ClosePrompt(refKey: string) {
    this.$callRefMethod<ClosePromptFunction>(refKey, 'ClosePrompt')
  }
}
