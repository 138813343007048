
import JigTextField from '@/components/input/JigTextField.vue'
import LabelWithHint from '@/components/input/LabelWithHint.vue'
import { JigConst } from '@/constants'
import { Tenant } from '@/store/modules/app/types'
import { Namespace, StandardObject } from '@/store/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

/**
 * The TeamRightsVue component contains fields that update Jig Metadata team rights relevant values.
 *
 * Fields included:
 * - 1. TeamShare config fields (JigMetadata.Tenants): `Team can view`, `Allow re-sharing` and `Tean can edit`
 * - Jig copyable by values (JigMetadata.CopyableBy)
 *
 * Also contains relevant logic to enable/disable or show/hide some fields based on certain values.
 */
@Component({
  components: {
    'jig-text-field': JigTextField,
    'label-with-hint': LabelWithHint,
  },
})
export default class TeamRightsVue extends Vue {
  @Prop({ type: Boolean, default: false })
  public canUpdateTeamViewValue!: boolean
  @Prop({ type: Boolean, default: false })
  public canUpdateTeamShareValue!: boolean
  @Prop({ type: Boolean, default: false })
  public isCopyableByOthersValue!: boolean
  @Prop({ type: String, default: '' })
  public copyableByValue!: string

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('teamSharePermissions', { namespace: Namespace.Jig })
  public teamSharePermissions!: JigConst.SharePermission[]

  @Mutation('updateTeamSharePermissions', { namespace: Namespace.Jig })
  public updateTeamSharePermissions: any

  private canUpdateTeamView: boolean = false
  private canUpdateTeamShare: boolean = false
  private copyableBy: string = ''
  private isCopyableByOthers: boolean = false
  private copyableByListItems: StandardObject[] = JigConst.copyableByList

  get teamSharePermission(): JigConst.SharePermission[] {
    return this.teamSharePermissions
  }

  set teamSharePermission(value: JigConst.SharePermission[]) {
    this.updateTeamSharePermissions(value)
  }

  @Watch('canUpdateTeamViewValue')
  private onCanUpdateTeamViewValueChanged(value: boolean) {
    this.canUpdateTeamView = value
  }

  @Watch('canUpdateTeamShareValue')
  private onCanUpdateTeamShareValueChanged(value: boolean) {
    this.canUpdateTeamShare = value
  }

  protected mounted() {
    this.canUpdateTeamView = this.canUpdateTeamViewValue
    this.canUpdateTeamShare = this.canUpdateTeamShareValue
    this.isCopyableByOthers = this.isCopyableByOthersValue
    this.copyableBy = this.copyableByValue
  }

  private get teamSharePermVals(): any {
    return {
      view: JigConst.SharePermission.view,
      reshare: JigConst.SharePermission.reshare,
      edit: JigConst.SharePermission.edit,
    }
  }

  public SetCopyableModels(payload: { isCopyableByOthers: boolean; copyableBy: string }) {
    this.isCopyableByOthers = payload.isCopyableByOthers
    this.copyableBy = payload.copyableBy
  }

  private onTeamShareChanged(value: any) {
    this.$emit('on-team-share-changed', value)
  }

  private onCopyableByOthersChange() {
    this.$emit('on-copyable-by-others-change', this.isCopyableByOthers)
  }

  private onCopyableByChange() {
    this.$emit('on-copyable-by-change', this.copyableBy)
  }

  private isCopyableByItemRestricted(value: string) {
    return (
      this.myTenant.Subscription.RestrictedJigCopyableByTypes && this.myTenant.Subscription.RestrictedJigCopyableByTypes.includes(value)
    )
  }

  public UpdateCopyableByList() {
    this.copyableByListItems.forEach((copyableItem: StandardObject) => {
      if (this.isCopyableByItemRestricted(copyableItem.value as string)) {
        copyableItem.disabled = true
      } else {
        copyableItem.disabled = false
      }
    })
  }
}
