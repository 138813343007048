
import JigTextField from '@/components/input/JigTextField.vue'
import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'jig-text-field': JigTextField,
  },
})
export default class TenantSwitchField extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean

  @Action('loadMyTenant', { namespace: Namespace.App })
  public loadMyTenant: any

  public numberRules: any = null
  public isSwitchingTenant: boolean = false
  public switchToTenantId: number = TenantHelpers.InvalidTenantID

  @Watch('myTenant')
  private async onMyTenantChanged(value: Tenant) {
    this.switchToTenantId = this.myTenant.ID
  }

  protected created() {
    this.numberRules = ValidationRules.RequiredNumber
  }

  protected mounted() {
    this.switchToTenantId = this.myTenant.ID
  }

  private async setTenantByID() {
    this.isSwitchingTenant = true
    const isSwitchSucceeded = await this.loadMyTenant({ TenantId: this.switchToTenantId, clearCache: true })
    this.isSwitchingTenant = false

    if (isSwitchSucceeded) {
      this.$emit('on-switch-successful', this.switchToTenantId)
    }
  }
}
