
import JigTextField from '@/components/input/JigTextField.vue'
import { UtilsConst } from '@/constants'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    'jig-text-field': JigTextField,
  },
})
export default class SearchFieldVue extends Vue {
  private searchCriteria: string = ''

  private searchItems() {
    this.$emit(UtilsConst.searchEvents.onSearch, this.searchCriteria)
  }

  private resetItems() {
    this.$emit(UtilsConst.searchEvents.onSearchReset)
  }
}
