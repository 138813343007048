<script>
import VTooltip from 'vuetify/lib/components/VTooltip/VTooltip'

export default {
  extends: VTooltip,
  props: {
    // Override VTooltip's prop with a default value
    disabled: {
      type: Boolean,
      default: false,
    },
    openDelay: {
      type: Number,
      default: 300, // To match Unity
    },
  },
}
</script>
