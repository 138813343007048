
import UserAvatar from '@/components/image/UserAvatar.vue'
import JigTooltip from '@/components/input/JigTooltip.vue'
import JigsFiltersMixin from '@/mixin/JigsFilters'
import { TenantUser } from '@/store/modules/app/types'
import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    'jig-tooltip': JigTooltip,
    'user-avatar': UserAvatar,
  },
})
export default class UsersPreviewListVue extends mixins(JigsFiltersMixin) {
  @Prop({ type: Array, default: () => [] })
  public userList!: TenantUser[]
  @Prop({ type: Array, default: () => [] })
  public flaggedUsers!: TenantUser[]
  @Prop({ type: Number, default: 4 })
  public previewLength!: number
}
